import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

import PoweredByLog from '../assets/images/poweredby-footer-logo.svg';

export const Footer = () => {
    return (
        <div className="container-fluid footer-container">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 d-flex align-items-center mb-lg-0 mb-xl-0 mb-xxl-0 mb-3">
                        <p className="mb-0">Copyright © Mindfree 2024 All Right Reserved</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 text-start text-lg-center text-xl-center text-xxl-center mb-lg-0 mb-xl-0 mb-xxl-0 mb-3">
                        <img src={PoweredByLog} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 d-flex align-items-center justify-content-start justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                        <ul className="footer-links ps-0 mb-0">
                            <li className="ps-lg-4 ps-xl-4 ps-xxl-4 ps-0 pe-lg-0 pe-xl-0 pe-xxl-0 pe-4">Terms & Condition</li>
                            <li className="ps-lg-4 ps-xl-4 ps-xxl-4 ps-0 pe-lg-0 pe-xl-0 pe-xxl-0 pe-4">Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}